/* eslint-disable @typescript-eslint/no-empty-object-type */
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { getPathPreferences } from "@gadgetinc/conventions";
import type {
  IAnyComplexType,
  IAnyModelType,
  IAnyStateTreeNode,
  IAnyType,
  IMSTArray,
  IMSTMap,
  INodeModelType,
  IOptionalType,
  ISimpleType,
  IStateTreeNode,
  IUnionType,
  Instance,
  ModelPropertiesDeclaration,
  SnapshotOrInstance,
} from "@gadgetinc/mobx-quick-tree";
import {
  ClassModel,
  getEnv,
  getParent,
  getParentOfType,
  getRoot,
  getSnapshot,
  hasEnv,
  isRoot,
  isStateTreeNode,
  types,
} from "@gadgetinc/mobx-quick-tree";
import { clamp, compact, isBoolean, isError, isFunction, isNil, isNull, isNumber, isUndefined, last, memoize } from "lodash";
import { DateTime } from "luxon";
import type { ObservableMap } from "mobx";
import { autorun } from "mobx";
import { nanoid } from "nanoid";
import qs from "qs";
import stableStringify from "safe-stable-stringify";
import { underscore } from "superflected";
import type { Constructor } from "type-fest";
import { SimpleTreeTestRoot } from "../spec/support/SimpleTestTreeRoot";
import type { AppSettings } from "./AppSettings";
import { DataType } from "./DataType";
import type { EnvironmentSlug, LegacyEnvironmentName } from "./Environment";
import type { ExtensionRegistry } from "./ExtensionRegistry";
import type { GadgetFlags } from "./GadgetFlags";
import type { LiveRoot } from "./LiveRoot";
import type { ModelField } from "./database/ModelField";
import type { Action } from "./database/behaviour/Action";
import type { LiveTreeEnv } from "./live-tree/LiveTree";
import { toSlug } from "./stringUtils";
import { FieldType } from "./type-system/FieldType";
import {
  doesVersionSupportGadgetVitePlugin,
  doesVersionSupportHydratedSandboxTypes,
  doesVersionSupportModelFieldsInActionParamsType,
  doesVersionSupportNamespace,
  getFastifyVersion,
  getNodeVersion,
} from "./versioning/FrameworkVersions";

export type IAnyModelOrUnionType = IAnyModelType | IUnionType<[IAnyModelType, ...IAnyModelType[]]>;

export type Optional<T, K extends keyof T> = Omit<T, K> & Partial<T>;
export interface FlashMessage {
  type: "success" | "info" | "warning" | "error";
  message: string;
}

export function assert<T>(value: T | undefined | null, message?: string): T {
  if (!value) {
    throw new Error("assertion error" + (message ? `: ${message}` : ""));
  }
  return value;
}

export type Thunk<T, Args extends any[] = []> = T | ((...args: Args) => T);

export const unthunk = <T, Args extends any[]>(value: Thunk<T, Args>, ...args: Args): T => {
  if (isFunction(value)) {
    return value(...args);
  } else {
    return value;
  }
};

export const weakMapMemoize = <T extends (...args: any) => any>(func: T, resolver?: (...args: any[]) => any): T => {
  const memoized = memoize(func, resolver);
  memoized.cache = new WeakMap();
  return memoized;
};

export const never = types.frozen<never>();
export const generateKey = () => nanoid(12);
export const keyType = types.optional(types.identifier, generateKey);

export const niceTypeName = (type: { name: string }): string => type.name;

/** Action definition function for mobx-state-trees that adds handy actions to them in development for debugging */
// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export const debugActions = (self: Instance<IAnyModelType>): {} => {
  return {
    debugAssign(values: any) {
      for (const [key, value] of Object.entries(values)) {
        self[key] = value;
      }
    },
  } as any;
};

/** Boolean checker and TypeScript type guard that checks if a given doodad is an instance of a mobx-state-tree type. It'd be nice to use mobx-state-tree's built in Type.is() for this, but .is() returns true for snapshots as well as instances, this checks explicitly for instances. */
export function isNodeOfType<T extends IAnyType>(node: SnapshotOrInstance<IAnyType>, type: T): node is Instance<T> {
  return isStateTreeNode(node) && type.is(node);
}

export const getAncestors = (node: Instance<IAnyType>) => {
  const result: IStateTreeNode[] = [];
  let cursor = node;
  while (!isRoot(cursor)) {
    cursor = getParent(cursor);
    result.push(cursor);
  }
  return result;
};

export const isAncestor = (node: Instance<IAnyType>, maybeAncestor: Instance<IAnyType>) => {
  let cursor = node;
  while (!isRoot(cursor)) {
    cursor = getParent(cursor);
    if (cursor == maybeAncestor) {
      return true;
    }
  }
  return false;
};

export const firstAncestorImplementing = (node: Instance<IAnyType>, func: string) => {
  let cursor = node;
  while (!isRoot(cursor)) {
    if (func in cursor) {
      return cursor;
    }
    cursor = getParent(cursor);
  }
  return null;
};

export const maybeGetParentOfType = <T extends IAnyType>(node: Instance<IAnyType>, type: T): Instance<T> | null => {
  try {
    return getParentOfType(node, type);
  } catch (e) {
    return null;
  }
};

/**
 * @deprecated Please use OrderedMaps to store ordered lists of things
 */
export const moveMSTArrayElement = <T extends IAnyType>(array: IMSTArray<T>, from: number, delta: number) => {
  const newItems = array.slice();
  const to = clamp(from + delta, 0, array.length - 1);
  newItems.splice(to, 0, newItems.splice(from, 1)[0]);
  array.replace(newItems);
};

/** A Date type that sets itself to now the first time an instance is created */
export const defaultNowDateType = types.optional(types.Date, () => DateTime.utc().toJSDate());

/**
 *  Returns a MST type that sticks a string literal in the actual nodes that TypeScript understands, so a Page without any other properties might have a JSON representation of `{"tag": "Page"}`. Useful for understanding what the heck is where and what type it is, as well as for typescript's discriminated unions and switch statements on unions of types that are typesafe automagically (switch node.tag ...)
 */
export const nodeTypeType = <T extends string>(type: T) => types.optional(types.literal(type), type);

export type GadgetModelType<Other = {}> = INodeModelType<
  {
    type: IOptionalType<ISimpleType<string>, [undefined]>;
    key: IOptionalType<ISimpleType<string>, [undefined]>;
  },
  Other
>;

export type GadgetDomainModelType<Other = {}> = INodeModelType<
  GadgetModelType["properties"] & {
    createdDate: IOptionalType<typeof types.Date, [undefined]>;
  },
  Other
>;

export const validationModelKey = (fieldKey: string, validationSpecId: string, order: number) => {
  return `Validation-${fieldKey}-${validationSpecId}-${order}`;
};

export const deterministicFieldStorageEpochKey = (fieldType: FieldType, fieldKey: string) => {
  return `${fieldType}-${fieldKey}`;
};

export const isFieldStorageEpochKeyDeterministic = (epochKey: string, field: ModelField) => {
  return epochKey === deterministicFieldStorageEpochKey(field.fieldType, field.key);
};

/**
 * Return a unique key for a Gadget MST model with the given name.
 */
export const gadgetModelKey = (name: string) => {
  return generateKey();
};

/**
 * Return the key of a type without its type prefix.
 */
export const stripModelKeyPrefix = (model: Instance<GadgetModelType<{}>>) => {
  return model.key.replace(`${model.type}-`, "");
};

/**
 * Gadget specific. Defines a mobx-state-tree model type with a name, a type property, and a key property. Similar to `gadgetDomainModelType`, but should be preferred for transient or framework-y types. Anything that goes in the database should use `gadgetDomainModelType`.
 * Equivalent to calling:
 *
 * ```typescript
 * types.model(name, { type: nodeTypeType(name), key: keyType})
 * ```
 *
 * The name is useful for debugging, the `type` property is useful for TypeScript discriminated unions typechecking and for mobx-state-tree's runtime union resolution, and the key is useful for always having an identifier for a tree node to use in references.
 * @deprecated use Class Models instead
 * @see GadgetModelClass
 */
export const gadgetModelType = <Name extends string, Properties extends ModelPropertiesDeclaration = {}>(name: Name, options: Properties) =>
  types
    .model(name, {
      type: nodeTypeType(name),
      key: types.optional(types.identifier, () => gadgetModelKey(name)),
      ...options,
    })
    .actions(debugActions);

/**
 * Defines a mobx-quick-tree base model class model type with a name, a type property, and a key property. Similar to `GadgetDomainModelClass`,  but should be preferred for transient or framework-y types. Anything that goes in the database should use `gadgetDomainModelType`.
 *
 * The name is useful for debugging, the `type` property is useful for TypeScript discriminated unions typechecking and for mobx-state-tree's runtime union resolution, and the key is useful for always having an identifier for a tree node to use in references.
 */
export const GadgetModelClass = <Name extends string, Properties extends ModelPropertiesDeclaration = {}>(
  name: Name,
  options: Properties
) =>
  ClassModel({
    type: nodeTypeType(name),
    key: types.optional(types.identifier, () => gadgetModelKey(name)),
    ...options,
  });

/**
 * Gadget specific. Defines a mobx-state-tree model type with a name, a type property, a key property, and bookkeeping properties for tracking when the object was created. Similar to `gadgetModelType`, but should be preferred for any non-transient or high value objects that get persisted.
 *
 * The name is useful for debugging, the `type` property is useful for TypeScript discriminated unions typechecking and for mobx-state-tree's runtime union resolution, and the key is useful for always having an identifier for a tree node to use in references.
 *
 * @deprecated use Class Models instead
 * @see GadgetDomainModelClass
 */
export const gadgetDomainModelType = <Name extends string, Properties extends ModelPropertiesDeclaration = {}>(
  name: Name,
  options: Properties
) =>
  types
    .model(name, {
      type: nodeTypeType(name),
      key: types.optional(types.identifier, () => gadgetModelKey(name)),
      createdDate: defaultNowDateType,
      ...options,
    })
    .actions(debugActions);

/**
 * Defines a mobx-quick-tree base model class model type with a name, a type property, a key property, and bookkeeping properties for tracking when the object was created. Similar to `gadgetModelClass`, but should be preferred for any non-transient or high value objects that get persisted.
 *
 * The name is useful for debugging, the `type` property is useful for TypeScript discriminated unions typechecking and for mobx-state-tree's runtime union resolution, and the key is useful for always having an identifier for a tree node to use in references.
 */
export const GadgetDomainModelClass = <Name extends string, Properties extends ModelPropertiesDeclaration = {}>(
  name: Name,
  options: Properties
) =>
  ClassModel({
    type: nodeTypeType(name),
    key: types.optional(types.identifier, () => gadgetModelKey(name)),
    createdDate: defaultNowDateType,
    ...options,
  });

/**
 * Returns an MST model type that has a `children` array of the passed type and actions for mutating that.
 * Useful for creating View nodes that hold a bunch of children and need to let the Selection mutate them.
 */
export const nodeContainerModel = <Name extends string, T extends IAnyType>(name: Name, childType: T) => {
  return types
    .model(name, {
      type: nodeTypeType(name),
      key: types.optional(types.identifier, () => gadgetModelKey(name)),
      createdDate: defaultNowDateType,
      children: types.array(childType),
    })
    .actions(debugActions)
    .actions((self) => ({
      addChild<Child extends T>(node: SnapshotOrInstance<Child>) {
        self.children.push(node);
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return last(self.children)! as Instance<Child>;
      },
      removeChild(node: Instance<T>) {
        self.children.remove(node);
      },
      moveChild(node: Instance<T>, delta: number) {
        moveMSTArrayElement(self.children, self.children.indexOf(node), delta);
      },
    }));
};

export type FrameworkFeaturesMap = {
  supportNamespace: boolean;
  supportViteGadgetPlugin: boolean;
  supportModelFieldsInActionParamsType: boolean;
  supportHydratedSandboxTypes: boolean;
  fastifyVersion: string;
  nodeVersion: string;
};

/**
 * Returns a map of framework features that are supported by the given framework version.
 *
 * The map is intended to act as a human-readable way to check if a feature is supported by a given framework version,
 * so each feature listed in the map should be based on the framework version only, and not on any other factors.
 */
export const getFrameworkFeaturesMap = (frameworkVersion: string): FrameworkFeaturesMap => {
  return {
    supportNamespace: doesVersionSupportNamespace(frameworkVersion),
    supportViteGadgetPlugin: doesVersionSupportGadgetVitePlugin(frameworkVersion),
    supportModelFieldsInActionParamsType: doesVersionSupportModelFieldsInActionParamsType(frameworkVersion),
    supportHydratedSandboxTypes: doesVersionSupportHydratedSandboxTypes(frameworkVersion),
    fastifyVersion: getFastifyVersion(frameworkVersion),
    nodeVersion: getNodeVersion(frameworkVersion),
  };
};

export interface GadgetStateTreeEnv extends LiveTreeEnv {
  extensionRegistry: ExtensionRegistry;
  envName: string | undefined;
  flags: GadgetFlags;
  docsDomain: string;
}

export const prettyJSON = (value: any) => JSON.stringify(value, null, 2);

export const getClientPackageScope = (env = "development") => {
  return env == "production" ? "@gadget-client" : `@gadget-client-${env}`;
};

/**
 * Get the name of the npm package for an application's generated client, like `@gadget-client/my-cool-blog`
 * We use a per-app package name for each client so that external projects can install clients from more than one gadget app
 **/
export const getClientPackageName = (platformEnv = "development", slug: string) => {
  if (process.env.NODE_ENV != "production") assert(slug == toSlug(slug), `Invalid slug "${slug}"`);
  return `${getClientPackageScope(platformEnv)}/${slug}`;
};

export const getGadgetEnv = (node: IStateTreeNode) => {
  const env = getEnv<GadgetStateTreeEnv>(node);
  if (!env.extensionRegistry) {
    throw new Error(`Can't get gadget env for tree node ${String(node)}, current env at the root doesn't have expected Gadget keys`);
  }
  return env;
};

export const getLogger = (node: IStateTreeNode) => {
  const env = getEnv<GadgetStateTreeEnv>(node);
  if (!env.console) {
    throw new Error(`Can't get gadget logger for tree node ${String(node)}, current env at the root doesn't have expected console key`);
  }

  return env.console;
};
/**
 * General purpose fast hashing function that works in the browser and in node.
 * Credit https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript
 **/
export const cyrb53 = (str: string, seed = 0) => {
  let h1 = 0xdeadbeef ^ seed,
    h2 = 0x41c6ce57 ^ seed;
  for (let i = 0, ch; i < str.length; i++) {
    ch = str.charCodeAt(i);
    h1 = Math.imul(h1 ^ ch, 2654435761);
    h2 = Math.imul(h2 ^ ch, 1597334677);
  }
  h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
  h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);
  return 4294967296 * (2097151 & h2) + (h1 >>> 0);
};

/**
 * Utility to produce a content hash from a list of a model's values which define it's content
 */
export const contentHash = Object.assign(
  (values: (string | number | boolean | null | undefined)[]) => {
    const stringified = values.map((value) => {
      if (isNull(value)) return "<<null>>";
      if (isUndefined(value)) return "<<undefined>>";
      if (isBoolean(value)) return `<<${String(value)}>>`;
      if (isNumber(value)) return `##${String(value)}`;
      return value;
    });

    const hash = cyrb53(stringified.join("///"));

    // when we want to diagnose why content hashes don't match, this lets us compare the whole tree of values that went into a hash. it's just slow so we only activate this during the tests
    if (contentHash.includeDebugInfo) {
      contentHash.debugInfos[hash] = values.map((value) => {
        if (isNumber(value) && contentHash.debugInfos[value]) {
          return contentHash.debugInfos[value];
        } else {
          return value;
        }
      });
    }

    return hash;
  },
  {
    debugInfos: {} as { [hash: number]: any[] },
    includeDebugInfo: false,
  }
);

export const validateNumber = (numberAsString: string, maximumDecimals?: number): boolean => {
  if (isFinite(Number(numberAsString))) {
    return !isNil(maximumDecimals) ? countDecimals(Number(numberAsString)) <= maximumDecimals : true;
  }
  return false;
};

export const countDecimals = (value: number) => {
  if (!isFinite(value)) {
    return 0;
  }
  return value.toString().split(".")[1]?.length || 0;
};

export const validateColorString = (colorString: string) => /^#([0-9a-fA-F]{6}|[0-9a-fA-F]{8})$/.test(colorString);

/**
 * These should be updated to refer to a library that can convert based on the currency when we support more than CAD and USD.
 */
export const convertCurrencyToSubUnits = (value: number) => {
  if (!isFinite(value)) {
    return 0;
  }
  return Math.round(value * 100); //round to ensure the result is an integer and protect against floating point math errors
};

export const convertCurrencyToBaseUnits = (value: number) => {
  if (!isFinite(value)) {
    return 0;
  }
  return value / 100.0;
};

export const numberOfDecimalsForCurrency = () => {
  return 2;
};

/**
 *  Get a JSON stringified snapshot of a model with stable (sorted) key order so the snapshot text is identical regardless of key insertion order
 */
export const stableSnapshotJSON = (node: SnapshotOrInstance<IAnyType>) => stableStringify(getSnapshot(node))!;

export const mapToObject = <V>(map: Map<string, V> | ObservableMap<string, V> | IMSTMap<any>): Record<string, V> =>
  Object.fromEntries(map.entries());

/**
 * Figure out if a given root MST node is a `LiveRoot`. We have a couple different live roots both client side, server side, and in production vs in the tests, so we use this utility to identify them instead of doing the same type checks everywhere.
 */
export const isLiveRoot = (node: IAnyStateTreeNode): node is Instance<typeof LiveRoot> => {
  return node.type == "ClientEnvironmentRoot" || node.type == "LiveRoot";
};

export const isTreeTestRoot = (node: IAnyStateTreeNode): node is Instance<typeof SimpleTreeTestRoot> => {
  return node.type == "SimpleTreeTestRoot";
};
/**
 * Get a string representing an error that is an `Error` object or anything else that might be `throw`n
 */
export const errorMessage = (error: unknown) => {
  if (typeof error == "string") {
    return error;
  } else if (isError(error)) {
    return error.message;
  } else {
    return String(error);
  }
};

/**
 * Some of our derived field nodes like `ObjectField`s are often their own MST root. This is because we generate them dynamically and treat them as values that go away most of the time -- they're derived data, ephemeral, and not stuck in the tree as persisted nodes.
 *
 * Because of this, they are disconnected from the greater context that might have generated them. `getRoot(someObjectField)` often returns the object field itself, or a quite close parent of it, and it doesn't get us the `WorkspaceRoot` or `FullServerEnvironmentRoot` or whatever of the whole application which generated it. So, we track the root that really did generate the `ObjectField` in a volatile `generatingParent` property so that our traversal functions can get back to the real root when they need to.
 * @deprecated use model classes
 * @see GeneratingParentClass
 */
export const defineGeneratingParentVolatile = () => {
  let generatingParent: Instance<IAnyModelType> | null = null;

  return {
    views: {
      get generatingParent() {
        return generatingParent;
      },
    },
    actions: {
      setGeneratingParent(value: Instance<IAnyModelType> | null) {
        generatingParent = value;
      },
    },
  };
};

/**
 * Some of our derived field nodes like `ObjectField`s are often their own MST root. This is because we generate them dynamically and treat them as values that go away most of the time -- they're derived data, ephemeral, and not stuck in the tree as persisted nodes.
 *
 * Because of this, they are disconnected from the greater context that might have generated them. `getRoot(someObjectField)` often returns the object field itself, or a quite close parent of it, and it doesn't get us the `WorkspaceRoot` or `FullServerEnvironmentRoot` or whatever of the whole application which generated it. So, we track the root that really did generate the `ObjectField` on its env's `generatingParent` property so that our traversal functions can get back to the real root when they need to.
 */
export const GeneratingParentClass = <T extends Constructor<any>>(Klass: T) => {
  class GeneratingParent extends Klass {
    get generatingParent(): unknown {
      if (hasEnv(this)) {
        return getEnv(this).generatingParent;
      }
    }
  }

  return GeneratingParent;
};

/**
 * Returns the environment with the `generatingParent` property set to the given node.
 * @param node - The state tree node.
 * @returns The environment object with the `generatingParent` property set to the given node.
 */
export const getEnvWithGeneratingParent = (node: IAnyStateTreeNode) => {
  return { ...getEnv(node), generatingParent: node };
};

/**
 * Returns all the parent nodes for any given node, similar to chained `getParent` calls from the MST API. But, we jump up through any ephemeral roots like `ObjectField` or `FloatingValueField` back to a really useful root like `LiveRoot` or `FullWorkspaceRoot` and keep returning parents.
 *
 * @see defineGeneratingParentVolatile
 */
export const getAllParentsThroughGeneratingRoot = (node: IAnyStateTreeNode): IAnyStateTreeNode[] => {
  const parents = [];
  let cursor = node;

  while (cursor) {
    if (isRoot(cursor)) {
      const newRoot = getGeneratingRoot(cursor);
      if (newRoot != cursor) {
        cursor = newRoot;
      } else {
        break;
      }
    } else {
      cursor = getParent(cursor);
    }

    parents.push(cursor);
  }

  return parents;
};

export const maybeGetParentOfTypeThroughGeneratingParent = <T extends IAnyComplexType>(
  node: IAnyStateTreeNode,
  type: T
): Instance<T> | null => {
  try {
    return getParentOfTypeThroughGeneratingParent<T>(node, type);
  } catch (e) {
    return null;
  }
};

export const getParentOfTypeThroughGeneratingParent = <T extends IAnyComplexType>(node: IAnyStateTreeNode, type: T): Instance<T> => {
  let cursor = node;

  while (cursor) {
    if (isRoot(cursor)) {
      if (cursor.generatingParent) {
        cursor = cursor.generatingParent;
      } else {
        break;
      }
    } else {
      cursor = getParent(cursor);
    }

    if (cursor && type.is(cursor)) {
      return cursor as unknown as Instance<T>;
    }
  }

  throw new Error("couldn't locate parent of type " + type.name);
};

/**
 * Returns the root MST node for any given node, similar to `getRoot` from the MST API. But, we jump up through any ephemeral roots like `ObjectField` or `FloatingValueField` back to a really useful root like `LiveRoot` or `FullWorkspaceRoot`.
 *
 * @see defineGeneratingParentVolatile
 */
export const getGeneratingRoot = (node: IAnyStateTreeNode): IAnyStateTreeNode => {
  const root = getRoot(node);
  // traverse to the root-most root that might have generated this root
  if ("generatingParent" in root && root.generatingParent) {
    return getGeneratingRoot(root.generatingParent);
  }
  return root;
};

/**
 * Returns a promise that when awaited on will sleep for the desired amount of time.
 * @param ms Time in milliseconds to sleep for
 * @returns A promise that will sleep for `ms` seconds.
 */
export const sleep = (ms: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

/**
 * Run the given function in a mobx observer for the side effect of mobx computeds being cached.
 */
export const runOnceInObserver = <T>(func: () => T) => {
  let result: T = null as any;
  let error: Error | null = null;
  const dispose = autorun(() => {
    try {
      result = func();
    } catch (innerError: any) {
      error = innerError;
    }
  });
  dispose();
  // eslint-disable-next-line @typescript-eslint/only-throw-error
  if (error) throw error;
  return result;
};

export function fieldTypeName(fieldType: FieldType) {
  switch (fieldType) {
    case FieldType.HasManyThrough:
      return "has many through";
    case FieldType.RoleAssignments:
      return "role list";
    case FieldType.DateTime:
      return "date / time";
    default:
      return underscore(fieldType).replace("_", " ");
  }
}

export const HARDCODED_FIELD_ORDER = {
  id: 0,
  createdAt: 1,
  updatedAt: 2,
  state: 3,
};

export const HARDCODED_ACTION_ORDER = {
  create: 0,
  update: 1,
  delete: 2,
};

export const HARDCODED_USER_ACTION_ORDER = {
  signUp: 0,
  signIn: 1,
  signOut: 2,
  update: 3,
  delete: 4,
  sendVerifyEmail: 5,
  verifyEmail: 6,
  sendResetPassword: 7,
  resetPassword: 8,
  changePassword: 9,
};

export const HARDCODED_USER_ACTION_REVERSED_ORDER = {
  signUp: 9,
  signIn: 8,
  signOut: 7,
  update: 6,
  delete: 5,
  sendVerifyEmail: 4,
  verifyEmail: 3,
  sendResetPassword: 2,
  resetPassword: 1,
  changePassword: 0,
};

export const HARDCODED_ACTION_REVERSED_ORDER = {
  create: 2,
  update: 1,
  delete: 0,
};

const MIN_PAST_TIMESTAMP = 31535999000;
export const MAX_FUTURE_TIMESTAMP = 90000000000000;

type RecordWithDate = {
  apiIdentifier?: string;
  createdDate: number | Date;
  key: string;
};

export function dateSortIterator<T extends RecordWithDate>(record: T): number {
  if (typeof record.createdDate === "number") {
    // If date is close to epoch zero, use a date far into the future as a fallback
    if (record.createdDate < MIN_PAST_TIMESTAMP) {
      return MAX_FUTURE_TIMESTAMP;
    } else {
      return record.createdDate;
    }
  } else {
    return record.createdDate.getTime();
  }
}

export const fieldSortOrderForApiIdentifier = (apiIdentifier: string) => {
  return HARDCODED_FIELD_ORDER[apiIdentifier as keyof typeof HARDCODED_FIELD_ORDER] ?? 1000;
};

/**
 * Lodash-centric sort function, to sort by a `createdDate` date, except in the case of the
 * 4 system values (ID, Created At, Updated At, and State), which are hardcoded to that order.
 */

export function actionIterator<T extends RecordWithDate>(record: T, actionOrderList: Record<string, number>) {
  const id = record.apiIdentifier as keyof typeof actionOrderList;
  if (actionOrderList[id] !== undefined) {
    return actionOrderList[id];
  }
  return dateSortIterator(record);
}

export function fieldIterator<T extends RecordWithDate>(record: T) {
  const id = record.apiIdentifier as keyof typeof HARDCODED_FIELD_ORDER;
  if (HARDCODED_FIELD_ORDER[id] !== undefined) {
    return HARDCODED_FIELD_ORDER[id];
  }

  return dateSortIterator(record);
}

export const actionSortIterators = (action: Action, isUserModel: boolean, opts?: { descending?: boolean }) => {
  let actionsOrders;
  if (isUserModel) {
    actionsOrders = opts?.descending ? HARDCODED_USER_ACTION_REVERSED_ORDER : HARDCODED_USER_ACTION_ORDER;
  } else {
    actionsOrders = opts?.descending ? HARDCODED_ACTION_REVERSED_ORDER : HARDCODED_ACTION_ORDER;
  }

  return actionIterator(action, actionsOrders);
};

/** Lodash-centric sort iterators to sort by `createdDate` and `key`, respectively */
export const fieldSortIterators = [fieldIterator, "key"] as const;

export const validationSortIterators = [dateSortIterator, "key"] as const;

/** Get data type display name */
export function getDataTypeDisplayName(type: string) {
  return String(DataType[type as keyof typeof DataType] ?? type) as DataType;
}

export function getMountedHTTPPathFromBackendRouteFilePath(filePath: string, fileBasedActions = false) {
  const pathPreferences = getPathPreferences({ fileBasedActions });
  const match = filePath.match(pathPreferences.backendRouteFileRegex);
  if (match) {
    const separator = match[4]?.startsWith(".") ? "" : "/";
    return (
      "/" +
      [match[2], match[4]]
        .filter((m) => m)
        .map((m) => m.replace(/^\/+|\/+$/g, ""))
        .join(separator)
    );
  } else {
    return null;
  }
}

export const stringBucketHash = (str: string, buckets: number) => {
  let hash = 0;

  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash |= 0; // Convert to 32-bit integer
  }

  return ((hash % buckets) + buckets) % buckets; // Ensure the result is within the range [0, 19]
};

export const defaultLogqlQuery = (environmentId?: string | null | undefined) => {
  return environmentId
    ? `{environment_id="${environmentId}"} | json | level=~"info|warn|error"`
    : `{environment_id=~".+"} | json | level=~"info|warn|error"`;
};

export const serializeObjectToHTTPQuery = (obj: Record<any, any>): string => {
  const data = qs.stringify(obj);
  return data ? `?${data}` : "";
};

export const deserializeObjectFromHTTPQuery = (query: string | undefined) => {
  if (!query) return undefined;
  const params = query.substr(1);
  return qs.parse(params);
};

export const syncLogqlQuery = (syncId: string, startAt: string, environment?: LegacyEnvironmentName) => {
  return serializeObjectToHTTPQuery({
    timeRange: `${new Date(startAt).toISOString()} to now`,
    logql: `${defaultLogqlQuery()}${syncId ? ` | json | connectionSyncId = "${syncId}"` : ""}`,
    environment: environment,
  });
};

export const getEnvironmentSlug = (node: IStateTreeNode) => {
  const settings = getAppSettings(node);
  return settings.environmentSlug;
};

declare const __brand: unique symbol;
type Brand<B> = { [__brand]: B };
/**
 * Helper type for defining a sub-type of another wide type that narrows the type for more runtime safety
 * https://egghead.io/blog/using-branded-types-in-typescript
 **/
export type Branded<T, B> = T & Brand<B>;
export type Unbranded<T> = Omit<T, typeof __brand>;

// helper for unbranding a value at runtime
export function unbrand<T, B>(value: Branded<T, B>): T {
  return value;
}

/** Isomorphic get me the appSettings function */
export const getAppSettings = (node: IStateTreeNode): AppSettings => {
  const root: Instance<IAnyModelType> = getGeneratingRoot(node);
  if (root.type == "FullServerEnvironmentRoot" || root.type == "ReadOnlySchemaRoot" || root.type?.endsWith("TestRoot")) {
    return root.settings;
  } else if (isLiveRoot(root)) {
    return assert(root.settings.get(), "app settings not yet loaded for getting from LiveRoot");
  } else if ("appSettings" in getEnv(node) && getEnv(node).appSettings) {
    return getEnv(node).appSettings;
  } else {
    throw new Error(`Unrecognized root type for getting the app settings from: ${root.type ?? root}`);
  }
};

/**
 * Simple helper function to get the file extension for code files based on the `typescript` property from `AppSettings`.
 */
export const getCodeScriptExtension = (node: IStateTreeNode) => {
  const settings = getAppSettings(node);
  return settings.canGenerateTypeScriptFiles ? "ts" : "js";
};

/**
 * Generate a new object with the keys and values of the given object swapped.
 * @example
 * ```ts
 * const newObject = setMapKeysToValues({
 *   a: "aa",
 *   b: "bb",
 *   c: "cc",
 * });
 * console.log(newObject);
 * // {
 * //   aa: "a",
 * //   bb: "b",
 * //   cc: "c",
 * // }
 * ```
 */
export const setMapKeysToValues = <K extends string | number | symbol, V extends string | number | symbol>(
  obj: Record<K, V>
): Record<V, K> => {
  return Object.entries<V>(obj).reduce((a, [key, value]) => ({ ...a, [value]: key }), {} as Record<V, K>);
};

/**
 * Simple helper function to only change the filename in a given path without changing the file extension. Example:
 * ```ts
 * changeFilenameInPath("path/to/file.txt", "newFilename");
 * // "path/to/newFilename.txt"
 * ```
 */
export const changeFilenameInPath = (filePath: string, newFilename: string) => {
  const splittedPaths = filePath.split("/");
  const filenameWithExtension = splittedPaths[splittedPaths.length - 1];
  const extension = filenameWithExtension?.split(".").pop();
  return `${splittedPaths.slice(0, splittedPaths.length - 1).join("/")}/${newFilename}.${extension}`;
};

export function* maybeYield<T>(value: T | Promise<T>): Generator<Promise<T>, T, T> {
  if (value instanceof Promise) {
    return yield value;
  } else {
    return value;
  }
}

/**
 * Returns a string that combines the namespace and the apiIdentifier.
 *
 * Example:
 * ```ts
 * getNamespacedApiIdentifier(["namespace1", "namespace2"], "apiIdentifier");
 * // Returns "namespace1/namespace2/apiIdentifier"
 * ```
 */
export const getNamespacedApiIdentifier = (namespace: string[], apiIdentifier: string) => {
  if (compact(namespace).length === 0) return apiIdentifier;
  return `${namespace.join("/")}/${apiIdentifier}`;
};

export const separateNamespacedApiIdentifier = (namespacedApiIdentifier: string) => {
  const parts = namespacedApiIdentifier.split("/");
  const apiIdentifier = parts[parts.length - 1];
  return { namespace: parts.slice(0, -1), apiIdentifier };
};

export const caseInsensitiveAlphabeticalSorter = (a: string, b: string) => {
  const aLowerCase = a.toLowerCase();
  const bLowerCase = b.toLowerCase();
  if (aLowerCase === bLowerCase) {
    // Same letters with different case, sort normally with capitals first
    return a > b ? 1 : -1;
  }
  return aLowerCase > bLowerCase ? 1 : -1;
};

// Manually disallow cancelling plan for https://admin.gadget.dev/team/11498
export const DisableCloseAccountTeamIds = ["11498"];

/**
 * The latest and greatest source of truth for all fields that should be filtered out of logs search results.
 * If you're looking to add a new field to the blocklist, please add it here.
 */
export const logsSearchFieldBlocklist: ReadonlyArray<string> = [
  "__typename",
  "action",
  "activity",
  "app_logs_service",
  "application_id",
  "area",
  "attributedToClient",
  "attributedToUserland",
  "causedByClient",
  "causedByUserland",
  "code",
  "commandKey",
  "conditionKey",
  "connectionKey",
  "connectionName",
  "contextID",
  "environmentId",
  "environment_id",
  "error_cause_stack",
  "error_stack",
  "expose",
  "exposeToClient",
  "exposeToSandbox",
  "fieldIdentifier",
  "host",
  "hostname",
  "inputValue",
  "isProductionEnv",
  "isRemoteError",
  "label",
  "logged",
  "modelKey",
  "pid",
  "platform_level",
  "reqId",
  "requestID",
  "resource",
  "rpcMessageID",
  "serverRole",
  "sessionID",
  "signal",
  "source",
  "sourceIdentifier",
  "span_id",
  "sql",
  "storageType",
  "storedValue",
  "time",
  "trace_flags",
  "transitType",
  "userVisible",
  "userspaceLogLevel",
  "workerID",
  "workerPid",
];

export const ggtDevCommand = (appSettings: AppSettings, environmentSlug?: EnvironmentSlug) => {
  let command = `ggt dev ./${appSettings.applicationSlug} --app=${appSettings.applicationSlug}`;
  if (appSettings.multiEnvironmentEnabled) {
    command += ` --env=${environmentSlug}`;
  }
  if (process?.env.NODE_ENV == "development") {
    command = `GGT_ENV=development ${command}`;
  }
  return command;
};
